import { getCookie, addAlert } from '../base';


function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}


export default function () {
    const URL = '/brokers/reviews/add/';


    $('.submit-form').on('submit', function (e) {
        const form = this,
            $form = $(form);

        e.preventDefault();

        console.log('You submitted form!');
        $form.find('.error').remove();
        $('.alertbox').remove();

        let csrftoken = getCookie('csrftoken'),
            data = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: URL,
            data: data,
            headers: {
                'X-CSRFToken': csrftoken
            },
            success: (data) => {
                window.location.href = `/brokers/review/${data.id}`;
            },
            error: (resp) => {
                addAlert('Произошла ошибка');
                const data = resp.responseJSON;
                $.each(data, (k) => {
                    let input = $form.find(`[name=${k}]`),
                        $input = $(input[0]),
                        text = data[k].join(', ');

                    $input.after(`<div class="error">${text}</div>`);
                });
            }
        });
    });
}
