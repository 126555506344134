import './css/main_pre.css';
import './css/letmoney.css';

import formHandler from './forms';


// Navigation Scripts to Show Header on Scroll-Up
jQuery(document).ready(function($) {
    var MQL = 1023,
        SHOW_UPDOWN = 900;

    const updownButton = $('div.updown').first();

    //primary navigation slide-in effect
    if ($(window).width() > MQL) {
        var headerHeight = $('.navbar-custom').height();
        $(window).on('scroll', {
                previousTop: 0
            },
            function() {
                var currentTop = $(window).scrollTop();
                //check if user is scrolling up
                if (currentTop < this.previousTop) {
                    //if scrolling up...
                    if (currentTop > 0 && $('.navbar-custom').hasClass('is-fixed')) {
                        $('.navbar-custom').addClass('is-visible');
                    } else {
                        $('.navbar-custom').removeClass('is-visible is-fixed');
                    }
                } else if (currentTop > this.previousTop) {
                    //if scrolling down...
                    $('.navbar-custom').removeClass('is-visible');
                    if (currentTop > headerHeight && !$('.navbar-custom').hasClass('is-fixed')) $('.navbar-custom').addClass('is-fixed');
                }
                this.previousTop = currentTop;
        });
    }

    $(window).on('scroll', function () {
        var currentTop = $(window).scrollTop();

        if (currentTop > SHOW_UPDOWN) {
            updownButton.show();
        } else {
            updownButton.hide();
        }
    });

    updownButton.on('click', function () {
        window.scrollTo(0, 0);
    });

    const $similar = $('.article__similar_block');
    if ($similar.length) {
        const input = $similar.find('input[name="id"]'),
            pId = input[0].value;


        $.ajax(`/api/similar/article/${pId}/`)
         .then(function (resp) {
            if (resp && resp.length) {
                $similar.append('<h4>Читайте также:</h4>');
            } else {
                return;
            }
            resp.forEach((i) => {
                const {title, intro, url} = i;
                let el = `<div class="col-xs-12 article__similar">
                        <a href="${url}"><strong>${title}</strong></a>
                    </div>`;
                $similar.append(el);

            });
         });
    }

    const $shareModal = $('#shareModal');
    $shareModal.on('shown.bs.modal', function () {
        $('.question__share-input').select();
    });

    formHandler();
});
