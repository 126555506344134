export function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


export function addAlert(text) {
    const dom = `
    <div class="alertbox__error">
        <span class="alertbox__closebtn">&times;</span>
        ${text}
    </div>`,
        $dom = $(dom);
    $dom.on('click', function () {
        $dom.remove();
    });

    $('body').append($dom);
}

export function addSuccess(text) {
    const dom = `
    <div class="alertbox__success">
        ${text}
    </div>`,
        $dom = $(dom);
    $dom.on('click', function () {
        $dom.remove();
    });

    $('body').append($dom);
}
